import "./App.css";
import { Slider } from "./components/Slider.jsx";
import { Suspense } from "react";
import { ExpandingCards } from "./components/ExpandingCards.jsx";
// import { ExpandingCardsNew } from './components/ExpandingCardsNew.jsx';
import { Navbar } from "./components/Navbar.jsx";
import { SnapCircle } from "./components/SnapCircle.jsx";
import { CardSlider } from "./components/CardSlider.jsx";
import { Footer } from "./components/footer.jsx";
// import { CardsDesign} from './components/CardsDesign.jsx'
import img1 from "./assets/img/modernize/Microsoft Dynamics.jpg";
import img2 from "./assets/img/modernize/Microsoft Business Central.jpg";
// import img3 from "./assets/img/modernize/Cyber Security.jpg";
import img3 from "./assets/img/cs.jpg";
import img4 from "./assets/img/modernize/Resource Augmentation.jpg";
import img5 from "./assets/img/optimize/Software Development.jpg";
import img6 from "./assets/img/optimize/Cloud Services.jpg";
import img7 from "./assets/img/optimize/DATA & AI Services.jpg";
import img8 from "./assets/img/optimize/Microsoft ERP Trainings.jpg";
import img9 from "./assets/img/optimize/Cloud Winkel.jpg";

import img11 from "./assets/img/flip_cards/home/health_care.webp";
import img12 from "./assets/img/flip_cards/home/Wholesale_&_Distribution.webp";
import img13 from "./assets/img/flip_cards/home/Manufacturing.webp";
import img14 from "./assets/img/flip_cards/home/FMCG.webp";
import img15 from "./assets/img/flip_cards/home/Insurance_&_Investments.webp";
import img16 from "./assets/img/flip_cards/home/Retail_Sector.webp";
import img17 from "./assets/img/flip_cards/home/E_commerce.webp";
import img18 from "./assets/img/flip_cards/home/education.webp";
import ScrollToTop from "./components/ScrollToTop.js";
import Technologies from "./components/Technologies.jsx";

import {
  faFileLines,
  faChartPie,
  faUserSecret,
  faDatabase,
} from "@fortawesome/free-solid-svg-icons";

import { faLaptopCode } from "@fortawesome/free-solid-svg-icons";
import { faCloud } from "@fortawesome/free-solid-svg-icons";
import { faMicrochip } from "@fortawesome/free-solid-svg-icons";
import { faServer } from "@fortawesome/free-solid-svg-icons";
import { faBoltLightning } from "@fortawesome/free-solid-svg-icons";

const Images = [
  {
    link: "microsoftDynamics",
    label: "Microsoft Dynamics 365/ AX2012",
    icon: faFileLines,
    text1: [
      { label: "Finance Management" },
      { label: "Supply Chain Management" },
      { label: "Retail Management" },
    ],
    text2: [
      { label: "Manufacturing" },
      { label: "HR/Payroll" },
      { label: "Support" },
    ],
    imgPath: img1,
  },
  {
    link: "BusinessCentral",
    label: "Microsoft Business Central 365",
    icon: faChartPie,
    text1: [
      { label: "Finance Management" },
      { label: "Supply Chain Management" },
      { label: "Project Managment" },
    ],
    text2: [
      { label: "Manufacturing" },
      { label: "HR/Payroll" },
      { label: "Support" },
    ],
    imgPath: img2,
  },
  {
    link: "cyberSecurity",
    label: "Cyber Security",
    icon: faUserSecret,
    text1: [
      { label: "Information Security Awareness" },
      { label: "Endpoint Security - EDR" },
      { label: "Vulnerability Assessment" },
      { label: "Penetration Testing" },
      { label: "Security Information & Event Monitoring (SIEM)" },
    ],
    text2: [
      { label: "Governance - Policies and Procedures" },
      { label: "Gap Assessments" },
      { label: "Risk Assessments" },
      { label: "Data Leakage Prevention (DLP)" },
      { label: "Compliance with ISO 27001,ISMS,NIST CSF,CIS,PCI etc" },
    ],
    imgPath: img3,
  },
  {
    link: "resourceAugmentation",
    label: "Resource Augmentation",
    icon: faDatabase,
    text1: [{ label: "Exploration" }, { label: "Sourcing" }],
    text2: [{ label: "Onboarding" }, { label: "Management" }],
    imgPath: img4,
  },
];

const Images2 = [
  {
    link: "softwareDevelopment",
    label: "Software Development",
    icon: faLaptopCode,
    text1: [
      { label: "Mobile Application" },
      { label: "Quality Insurrance" },
      { label: "DevOps" },
    ],
    text2: [{ label: "Experience Design" }, { label: "Website Development" }],
    imgPath: img5,
  },
  {
    link: "cloudServices",
    label: "Cloud Services",
    icon: faCloud,
    text1: [{ label: "Cloud Consultancy" }, { label: "Cloud Integration" }],
    text2: [{ label: "Application Transformation" }],
    imgPath: img6,
  },
  {
    link: "dataAIServices",
    icon: faMicrochip,
    label: "DATA & AI Services",
    text1: [
      { label: "Data Platform Modernization" },
      { label: "Enterprise AI Integration" },
    ],
    text2: [{ label: "Data Management" }],
    imgPath: img7,
  },
  {
    link: "training",
    label: "Microsoft ERP Trainings",
    icon: faServer,
    text1: [
      { label: "Microsoft D365 Trainings" },
      { label: "Microsoft Business Central" },
    ],
    text2: [{ label: "CyberSecurity Trainings" }],
    imgPath: img8,
  },
  {
    link: "cloudWinkel",
    label: "Cloud Winkel",
    icon: faBoltLightning,
    text1: [
      { label: "Point of sale" },
      { label: "Inventory" },
      { label: "Customers" },
    ],
    text2: [
      { label: "Purchase" },
      { label: "Reporting" },
      { label: "Order Management" },
    ],
    imgPath: img9,
  },
];
const top_details = {
  heading: "MODERNIZEE",
  para: "Stay current with technology and ensure your business operations run smoothly and without errors!",
};
const top_details2 = {
  heading: "OPTIMIZE",
  para: "Evolve into a cutting-edge enterprise, drive innovation, and achieve unprecedented growth!",
};

const cardsData = {
  title_1: {
    text: "Industries we Serve",
    light: false,
  },
  title_2: {
    text: "",
    light: false,
  },
  para: "To help you achieve your business goals, the Enuvision team is standing by to provide flexible and comprehensive solutions. Enuvision ERP implementation and software application development are based on experience in key business verticals, and are carried out by experts who are familiar with the unique issues that your company faces. The ability to tackle the most complex business problems and envision solutions that drive business results is what sets Enuvision Technologies apart!.",
  cards: [
    {
      heading: "Healthcare",
      image: img11,
      points: [],
      details:
        "Enuvision solutions enhance healthcare by streamlining operations, improving patient care, and ensuring compliance. Key features include financial management, supply chain optimization, and real-time tracking of inventory and patient records. These tools enable informed decision-making and better patient outcomes while optimizing costs and performance.",
    },
    {
      heading: "WholeSale & Distribution",
      image: img12,
      points: [],
      details:
        "Enuvision optimize wholesale and distribution operations by improving inventory management and supply chain visibility. With integrated order processing and financial management tools, businesses reduce costs, enhance efficiency, and quickly respond to market demands.",
    },
    {
      heading: "Manufacturing",
      image: img13,
      points: [],
      details:
        "Leveraging our expertise in manufacturing, micro-vertical functionality, business process optimization, and advanced technologies, we utilize Microsoft ERP to deliver comprehensive solutions for complex challenges. Our approach ensures streamlined operations and enhanced decision-making, helping businesses achieve their goals efficiently.",
    },
    {
      heading: "FMCG",
      image: img14,
      points: [],
      details:
        "Enuvision empower to streamline supply chain management and inventory control in the FMCG industry. With integrated sales and financial tools, companies can quickly adapt to market trends and leverage real-time analytics for informed decision-making, reducing costs and enhancing customer satisfaction.",
    },
    {
      heading: "Insurance & Investments",
      image: img15,
      points: [],
      details:
        "Our Specialized  solutions streamline operations and enhance compliance in the investment and insurance industry. With tools for financial reporting and risk assessment, organizations optimize workflows and leverage real-time analytics for informed decision-making, reducing risks and improving client service.",
    },
    {
      heading: "Retail Sector",
      image: img16,
      points: [],
      details:
        "Enuvision leads in retail management by delivering exceptional customer experiences, optimizing inventory processes, and integrating omni-channel solutions. Emphasizing personalized service, data-driven strategies, and sustainability, we enhance value and drive growth in the competitive retail landscape.",
    },
    {
      heading: "E-commerce",
      image: img17,
      points: [],
      details:
        "Microsoft D365 ERP solutions optimize order management and inventory control for e-commerce. Integrated tools enhance transaction efficiency, while real-time analytics provide insights for better decision-making, improving operational efficiency and customer satisfaction.",
    },
    {
      heading: "Education",
      image: img18,
      points: [],
      details:
        "Enuvision solutions elevate educational institutions by streamlining operations, enhancing administrative efficiency, and ensuring compliance. With ERP integration at the core, our offerings include advanced financial management, supply chain optimization, and real-time tracking of inventory and academic records.",
    },
  ],
};

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Navbar />
      <Slider />
      {/* <ExpandingCards Images={Images}/> */}
      {/* <ExpandingCards Images={Images2}/> */}
      {/* <Suspense>
      <ExpandingCardsNew Images={Images} top_details={ top_details}/>
      </Suspense> */}
      <ExpandingCards Images={Images} topDetails={top_details} />
      <Suspense>
        <ExpandingCards Images={Images2} topDetails={top_details2} />
      </Suspense>
      <SnapCircle />
      <CardSlider cardsData={cardsData} />
      <Technologies />
      <Footer />
      {/* <CardsDesign Images={Images}/> */}
    </div>
  );
}

export default App;
