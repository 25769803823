import { useState, useEffect } from "react";
import img1 from "../assets/img//MicrosoftDynamics.jpg";
import img2 from "../assets/img/MicrosoftBusinessCentral.jpg";
import img3 from "../assets/img/Cyber_security.jpg";
import img4 from "../assets/img/ResourceAugmentation2.jpg";
import img5 from "../assets/img/Cloud_Wrinkel.jpg";

import vidBanner from "../assets/video/Video_Banner.mp4";
import "../App.css";
import "./Slider.css";

/*
AI-Powered Business with Microsoft Dynamics 365 ERP
Business Central: Your Seamless ERP Solution
Safeguard You Digital Journey
Discover, Connect & Cultivate
Innovation with Cloud Technology
*/

const Images = [
  {
    label: "Microsoft Dynamics 365 /AX2012",
    text: "AI-Powered Business with Microsoft Dynamics 365 ERP",
    imgPath: img1,
  },
  {
    label: "Microsoft Business Central 365",
    text: "Business Central: Your Seamless ERP Solution",
    imgPath: img2,
  },
  {
    label: "Cyber Security",
    text: "Safeguard You Digital Journey",
    imgPath: img3,
  },
  {
    label: "Resource Augmentation",
    text: "Discover, Connect & Cultivate",
    imgPath: img4,
  },
  {
    label: "Cloud Wrinkle",
    text: "Innovation with Cloud Technology",
    imgPath: img5,
  },
];

export const Slider = () => {
  const totalImages = Images.length;
  const [index, setIndex] = useState(0);
  // const [fade, setFade] = useState(false);

  const goToNextPicture = () => {
    setIndex((prevIndex) => (prevIndex + 1) % totalImages);
  };

  const goToPicture = (idx) => {
    setIndex(idx);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // setFade(true);
      setTimeout(() => {
        goToNextPicture();
        // setFade(false);
      }, 500);
    }, 3000);

    return () => clearInterval(interval);
  });

  return (
    <div className="container">
      <div className="slider">
        <video autoPlay muted loop className="slide-media-video">
          <source src={vidBanner} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {Images.map((image, idx) => (
          <div key={idx} className={`slide ${idx === index ? "active" : ""} `}>
            {idx === index && (
              <>
                <div className="overlay overlay-slider">
                  <h1 className="inter-headings-h2 img-text">{image.text}</h1>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
      <div className="slider-social-icons">
        <a href="https://web.facebook.com/profile.php?id=61557812813741">
          <i className="fa fa-facebook"></i>
        </a>
        <a href="https://www.instagram.com/enuvisions/">
          <i className="fa fa-instagram"></i>
        </a>
        <a href="https://www.linkedin.com/company/enuvision">
          <i className="fa fa-linkedin"></i>
        </a>
      </div>
      <div className="slider-nav">
        {Images.map((image, idx) => (
          <div
            key={idx}
            className={`selectors ${idx === index ? "active" : ""}`}
            onClick={() => goToPicture(idx)}
            aria-label={`Go to ${image.label}`}
          >
            <div className="number">{idx + 1}</div>
            <button className="inter-headings-h3">{image.label}</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slider;
