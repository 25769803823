import { lazy ,Suspense } from "react";
import {useState , useEffect} from "react";

import Navbar from "../components/Navbar";
import Footer from "../components/footer";

import img1 from '../assets/img/Support.jpg'

// import mainBanner from '../assets/img/main_banners'

import { faHeadset } from '@fortawesome/free-solid-svg-icons'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { faUserTie } from '@fortawesome/free-solid-svg-icons'
import { faTable } from '@fortawesome/free-solid-svg-icons'
import { faGears } from '@fortawesome/free-solid-svg-icons'
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons'
import { faDatabase } from '@fortawesome/free-solid-svg-icons'
import { faArrowUpRightDots } from '@fortawesome/free-solid-svg-icons'
import ScrollToTop from "../components/ScrollToTop";

const ServiceMainPage = lazy(() => import('../components/ServiceMainPage'));
const ServiceDetailPage = lazy(() => import('../components/ServiceDetailPage'));
const BoxDetails = lazy(() => import('../components/BoxDetails'));


const mainContent = {
    heading : "Support",
    title : "Dynamics 365 Support and Maintenance Services",
    para : "Enhance your Dynamics 365 experience and drive business growth with our customized Microsoft Dynamics maintenance and support services.",
    button : "Book a Consultant",
    image : img1
}

const data= {
    heading : "Stay ahead of the competition with proactive Microsoft Dynamics 365 Support.",
    para : "Ensure your business stays operational and get the most out of your Dynamics 365 solutions with Enuvision, your Dynamics 365 Managed Services and Support Partner. We empower you to leverage the full potential of intelligent Microsoft Business Applications suite solutions, including ERP and CRM. With our wealth of experience, our committed and adaptable support nurtures your strong solution, guaranteeing smooth business processes and encouraging ongoing business expansion.",
    image1 : img1,
    points : []
}

const boxData = {
    heading_1 : {
        text : "Enhance your Dynamics 365 experience with our robust support system.",
        light : false
    },
    heading_2 : {
        text : "",
        light : false 
    },
    cards :[
        {
            title : 'Technical Support',
            icon : faHeadset,
            details1 : 'With our Microsoft Dynamics technical assistance, you can streamline your business processes, effectively address technical challenges, and configure advanced functionalities tailored to your unique needs. Our expert team provides comprehensive support, helping you optimize the use of Dynamics 365 to enhance productivity and efficiency. We work closely with you to identify areas for improvement, implement best practices, and ensure seamless integration with existing systems. By maximizing the capabilities of Dynamics 365, we enable you to achieve a higher return on investment (ROI) and drive continuous growth for your organization. Our commitment to delivering tailored solutions empowers your teams to leverage the full potential of Microsoft Dynamics, ultimately enhancing overall performance and customer satisfaction.',
            points : [],
            link : 'softwareDevelopment'
        },
        {
            title : 'Functional support',
            icon : faCircleInfo,
            details1 : 'Customize and set up Dynamics 365 modules to align with your business requirements, enhancing operational efficiency and user engagement. We begin with a thorough analysis of your processes to tailor the ERP and CRM modules to your unique workflows. Our expert team ensures seamless integration with existing systems, facilitating smooth operations across departments. By configuring advanced functionalities and automating processes, we boost user productivity and engagement. With training and ongoing support, we empower your team to leverage the full capabilities of Dynamics 365, ensuring optimal performance that supports your strategic objectives.',
            points : [],
            link : 'microsoftDynamics'
        },
        {
            title : 'System administration and monitoring',
            icon : faUserTie,
            details1 : 'We provide comprehensive management of your Dynamics 365 (D365) environment, covering security, performance monitoring, and issue resolution. Our dedicated team implements advanced security measures to protect your data and ensure compliance. We continuously monitor system performance, identifying optimization opportunities and preventing potential issues before they escalate. In the event of any problems, our support team quickly troubleshoots and resolves them, minimizing downtime. By managing your D365 environment holistically, we help maximize the platform\'s capabilities and enhance user experience, allowing you to focus on your core business activities.',
            points : [],
            link : 'microsoftBusiness'
        },
        {
            title : 'Customization and configuration',
            icon : faTable,
            details1 : 'We customize your Dynamics 365 ERP and CRM systems with bespoke workflows, integrations, and tailored solutions to meet your unique business needs. Our process includes assessing your operations to design efficient workflows and ensure seamless integration with existing systems for unified data flow. We enhance user experience through custom solutions and intuitive interface modifications, along with comprehensive training and support. This personalized approach enables greater operational efficiency, improved customer engagement, and supports business growth.',
            points : [],
            link : 'CloudServices'
        },
        {
            title : 'Training and knowledge transfer',
            icon : faGears,
            details1 : 'Our Microsoft Dynamics 365 Support Services include tailored training and ongoing support to optimize system utilization. We assess your team\'s needs to create customized training materials and deliver sessions through workshops, virtual training, or one-on-one coaching. Additionally, we provide continuous assistance via a dedicated help desk and regular check-ins to address challenges. This combined focus on training and support empowers your team to maximize the benefits of Microsoft Dynamics 365, enhancing productivity and confidence in using the system.',
            points : [],
            link : 'dataAIServices'
        },
        {
            title : 'Integration support',
            icon : faNetworkWired,
            details1 : 'We seamlessly integrate Dynamics 365 with the Microsoft technology stack and third-party applications, creating a cohesive operational ecosystem. Our custom integration solutions ensure data accuracy and consistency by automating data transfers and synchronizing information, reducing manual entry errors. This enhances accessibility, allowing teams to access critical data from a unified interface, fostering collaboration and improving productivity. We also implement monitoring tools to ensure optimal integration performance. Our comprehensive approach empowers your organization to optimize operations and drive business growth.',
            points : [],
            link : 'dataAIServices'
        },
        {
            title : 'Performance optimization',
            icon : faDatabase,
            details1 : 'Our team specializes in identifying and resolving performance bottlenecks in your Dynamics 365 environment. We analyze your system to pinpoint inefficiencies and implement targeted strategies for optimization, including configuration adjustments and resource allocation. To minimize downtime, we establish monitoring and alert systems that proactively detect potential issues. Through regular updates and performance checks, we ensure a high-performing Dynamics 365 environment, enabling users to access a reliable system that enhances productivity and supports business success. With our expertise, you can maximize your Dynamics 365 investment and improve operational efficiency.',
            points : [],
            link : 'dataAIServices'
        },
        {
            title : 'Data management support',
            icon : faArrowUpRightDots,
            details1 : 'We empower businesses with Dynamics 365 Support for data management tasks, including cleansing, migration, integration, and maintenance. Our team ensures data accuracy by removing duplicates, correcting errors, and standardizing formats. We facilitate smooth data migration to Dynamics 365 and connect it with other applications for seamless data flow. Additionally, we provide ongoing maintenance to monitor data quality and manage changes. Our support enhances data organization, enabling informed decision-making and improved operational efficiency for business growth.',
            points : [],
            link : 'dataAIServices'
        },
    ]
}


export const Support = () => {
    const [loadedBanner , setLoadedBanner] = useState(false);

    useEffect(() => {
      const img = new Image();
      img.src = mainContent.image;
      img.onload = () => {
        setLoadedBanner(true);
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainContent.image]);
  
    if(!loadedBanner){
      return;
    }
  
    return(
        <div className="support-page">
            <ScrollToTop />
            <Navbar />
            <Suspense>
                <ServiceMainPage mainContent={mainContent} />
            </Suspense>
            <Suspense>
                <ServiceDetailPage details={data} />
            </Suspense>
            <Suspense>
                <BoxDetails data={boxData} />
            </Suspense>
            <Footer />
        </div>
    );
}

export default Support;