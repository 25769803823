import React from "react";
import Navbar from "../components/Navbar";
import { lazy , Suspense } from "react";
import {useState , useEffect} from "react";

import Footer from "../components/footer";

import { faLaptopCode } from "@fortawesome/free-solid-svg-icons";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { faCloud } from "@fortawesome/free-solid-svg-icons";
import { faMicrochip } from "@fortawesome/free-solid-svg-icons";

import {
  faSearch,
  faMagnifyingGlassLocation,
  faShip,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

import img6 from "../assets/img/Resource_Augmentation/The_Enuvision_Approach.jpg";

import mainBanner from "../assets/img/main_banners/ResourceAugmentationpage.webp";

import resourceBackImg from "../assets/img/linesbackground/KeyFeatures/2.png";

import ScrollToTop from "../components/ScrollToTop";


const ServiceMainPage = lazy(() => import('../components/ServiceMainPage'));
const BoxDetails = lazy(() => import('../components/BoxDetails'));
const ServiceGridPoints = lazy(() => import('../components/ServiceGridPoints'));
const ResourceTable = lazy(() => import('../components/ResourceTable'));


const mainContent = {
  heading: "Resource Augmentation",
  title:
    "Enhance performance and ensure project success through Staff Augmentation.",
  para: "Share your information with us, and we'll reach out to you.",
  button: "Let's Connect",
  image: mainBanner,
};

const GridPoints = {
  heading_1: {
    text: "",
    light: false,
  },
  heading_2: {
    text: "Enuvision <b>Approach</b>",
    light: true,
  },
  back_img: img6,
  points: [
    {
      icon: faSearch,
      title: "Exploration",
      content:
        "We start by understanding your business needs, goals, and culture. Next, we gather project specifics, including timelines and team structure, to customize our staff augmentation services to align with your business objectives.",
    },
    {
      icon: faMagnifyingGlassLocation,
      title: "Sourcing",
      content:
        "Our team meticulously sources and evaluates top professionals in the industry. We take pride in our extensive talent network, comprising skilled developers, proficient business analysts, seasoned project managers, and adept functional consultants, ensuring a tailored fit for your needs.",
    },
    {
      icon: faShip,
      title: "Onboarding",
      content:
        "We manage the onboarding process to ensure the selected resources quickly align with your project requirements and company culture. This involves training, integrating them into the team, and establishing clear expectations for their role and responsibilities.",
    },
    {
      icon: faUsers,
      title: "Management",
      content:
        "Our adept project managers provide consistent feedback, coaching, and evaluations to guarantee projects are completed on time, within budget, and to your satisfaction. We also offer ongoing support to ensure a smooth transition and handover process.",
    },
  ],
};

const boxData = {
  heading_1: {
    text: "",
    light: false,
  },
  heading_2: {
    text: "Augmented Resource Solutions across various domains",
    light: false,
  },
  cards: [
    {
      title: "Software Development",
      icon: faLaptopCode,
      details1:
        "Optimize your software development capacity with our services in mobile apps, UI/UX design, quality assurance, and DevOps. We set new benchmarks for excellence and offer tailored staff augmentation from inception to launch.Our experts collaborate with your team to ensure your software is functional and visually appealing. We rigorously test applications for optimal performance and implement DevOps practices to streamline processes. This approach ensures you have the expertise needed to succeed while you focus on your core business objectives.",
      points: [],
      details2: "",
      link: "softwareDevelopment",
    },
    {
      title: "Microsoft Dynamics 365",
      icon: faLayerGroup,
      details1:
        "We empower your organization with comprehensive Dynamics 365 ERP services, including upgrades, implementations, support, optimizations, and customizations. Our team tailors solutions to streamline operations and enhance efficiency. We ensure smooth transitions during implementation and upgrades, while providing ongoing support to maintain system functionality. Our customization services adapt Dynamics 365 to your unique workflows, ensuring scalability as your business evolves. By maximizing the value of Dynamics 365 ERP, we enable your teams to operate effectively and drive growth.",
      points: [],
      details2: "",
      link: "microsoftDynamics",
    },
    {
      title: "Microsoft Power Platform",
      icon: faGear,
      details1:
        "Empower your team to create custom applications and streamline processes with our staff augmentation services utilizing Microsoft Power Platform. Our experts collaborate with your team to design solutions using Power Apps, Power Automate, and Power BI. We identify automation opportunities and ensure seamless integration with existing systems. Additionally, we provide training and support to enhance your team's skills in leveraging Power Platform tools, driving efficiency and business growth.",
      points: [],
      details2: "",
      link: "microsoftBusiness",
    },
    {
      title: "Cloud",
      icon: faCloud,
      details1:
        "Embark on a secure and efficient cloud journey with our expert staff augmentation services. Our team manages strategic development, seamless migration, and ongoing optimization tailored to your needs. We start with an assessment of your infrastructure to create a custom cloud strategy. Our migration process ensures data integrity and security while minimizing disruption. Post-migration, we optimize your cloud environment through continuous monitoring and insights for improvement. With our expertise, your organization can fully leverage cloud technology, ensuring a secure, efficient, and future-proof digital infrastructure.",
      points: [],
      details2: "",
      link: "CloudServices",
    },
    {
      title: "Data And AI",
      icon: faMicrochip,
      details1:
        "Enhance your cybersecurity posture with our staff augmentation services. Our team provides strategic development, seamless migration, and ongoing optimization to secure your environment. We assess your security landscape to identify vulnerabilities and create a tailored strategy. Our secure migration minimizes disruptions while protecting data integrity. After migration, we focus on continuous optimization, monitoring your cybersecurity environment and providing insights to address emerging threats. With our expertise, we ensure your digital infrastructure is secure and future-ready, empowering your organization to navigate today’s cybersecurity challenges confidently.",
      points: [],
      details2: "",
      link: "dataAIServices",
    },
  ],
};

const resourceData = {
  heading: "Choosing your talent path:",
  para: "IT Resource Augmentation <b>VS</b> Freelancing",
  back_img: resourceBackImg,
  columns: [
    {
      title: "Standards",
      points: [
        { point: "Skill specialization", subpoint: [] },
        { point: "Cost structure", subpoint: [] },
        { point: "Onboarding time", subpoint: [] },
        { point: "Project control", subpoint: [] },
        { point: "Availability", subpoint: [] },
        { point: "Scalability", subpoint: [] },
      ],
    },
    {
      title: "IT Resource Augmentation",
      points: [
        {
          point: "Access to a wide range of specialized skills.",
          subpoint: [],
        },
        { point: "Fixed hourly or project rates.", subpoint: [] },
        { point: "Generally quick onboarding process.", subpoint: [] },
        { point: "High level of control over the project.", subpoint: [] },
        { point: "Availability is more stable and consistent.", subpoint: [] },
        {
          point: "Easy to scale up or down based on project needs.",
          subpoint: [],
        },
      ],
    },
    {
      title: "Hiring a Freelancing",
      points: [
        {
          point:
            "Usually focused on a particular skill set or specialized niche.",
          subpoint: [],
        },
        {
          point:
            "Variable rates depending on freelancer's terms and experience.",
          subpoint: [],
        },
        {
          point: "May require more time for onboarding and setup.",
          subpoint: [],
        },
        {
          point: "Limited control, especially in freelance marketplaces.",
          subpoint: [],
        },
        {
          point: "Availability may vary based on the freelancer's schedule.",
          subpoint: [],
        },
        {
          point: "Limited scalability for ongoing or larger projects.",
          subpoint: [],
        },
      ],
    },
  ],
};

export const ResourceAugmentation = () => {
  const [loadedBanner , setLoadedBanner] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = mainContent.image;
    img.onload = () => {
      setLoadedBanner(true);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContent.image]);

  if(!loadedBanner){
    return;
  }

  return (
    <div className="resourceAugmentation">
      <ScrollToTop />
      <Navbar />
      <Suspense>
        <ServiceMainPage mainContent={mainContent} />
      </Suspense>
      <Suspense>
        <BoxDetails data={boxData} />
      </Suspense>
      <Suspense>
        <ServiceGridPoints data={GridPoints} />
      </Suspense>
      <Suspense>
        <ResourceTable data={resourceData} />
      </Suspense>
      <Footer />
    </div>
  );
};

export default ResourceAugmentation;
