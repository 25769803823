import React, { useRef , useState } from 'react';
import './InformationForm.css';

const InformationForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contactNumber: '',
    position: '', // Added position field
    Comments: '',
    linkedIn: '',
    resume: null,
    about: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    contactNumber: '',
    position: '',
    Comments: '',
    linkedIn: '',
    resume: '',
    about: '',
  });

  const resumeRef = useRef(null); // Using ref to access the file input element


  const [submitSuccess, setSubmitSuccess] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      resume: e.target.files[0],
    });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = 'Name is required';
      valid = false;
    }

    if (!formData.email) {
      newErrors.email = 'Email is required';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
      valid = false;
    }

    if (!formData.contactNumber) {
      newErrors.contactNumber = 'Contact Number is required';
      valid = false;
    } else if (!/^\d+$/.test(formData.contactNumber)) {
      newErrors.contactNumber = 'Contact Number must be digits only';
      valid = false;
    }

    if (!formData.position) {
      newErrors.position = 'Position is required';
      valid = false;
    }

    if (!formData.linkedIn) {
      newErrors.linkedIn = 'LinkedIn profile link is required';
      valid = false;
    } else if (!/^https?:\/\/(www\.)?linkedin\.com\/.*$/.test(formData.linkedIn)) {
      newErrors.linkedIn = 'Please enter a valid LinkedIn URL';
      valid = false;
    }

    if (!formData.resume) {
      newErrors.resume = 'Please upload your resume';
      valid = false;
    } else if (formData.resume.type !== 'application/pdf') {
      newErrors.resume = 'Only PDF format is allowed for the resume';
      valid = false;
    }

    if (!formData.about) {
      newErrors.about = 'Please tell us about yourself';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log('Form submitted:', formData);

      // Prepare form data for backend submission (resume as file)
      const formDataToSubmit = new FormData();
      formDataToSubmit.append('name', formData.name);
      formDataToSubmit.append('email', formData.email);
      formDataToSubmit.append('contactNumber', formData.contactNumber);
      formDataToSubmit.append('position', formData.position);
      formDataToSubmit.append('Comments', formData.Comments);
      formDataToSubmit.append('linkedIn', formData.linkedIn);
      formDataToSubmit.append('resume', formData.resume);
      formDataToSubmit.append('about', formData.about);

      fetch('https://localhost:4001', {
        method: 'POST',
        body: formDataToSubmit, // Send as FormData
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          setSubmitSuccess('Form submitted successfully!');
          console.log('Data received:', data);
        })
        .catch((error) => {
          setSubmitSuccess('There was an error submitting the form.');
          console.error('There was a problem with the fetch operation:', error);
        });

      // Reset the form fields
      setFormData({
        name: '',
        email: '',
        contactNumber: '',
        position: '',
        Comments: '',
        linkedIn: '',
        resume: null,
        about: '',
      });
          // Clear the file input after form submission
    if (resumeRef.current) {
      resumeRef.current.value = ""; // Reset the file input field
    }
    }
  };

  return (
    <div className="information-form-section">
      <div className="information-form-container">
        <h2>Career Information</h2>
        <form onSubmit={handleSubmit} className="information-form">
          {/* Form inputs and error messages */}
          {submitSuccess === 'Form submitted successfully!' && <div className="inter-headings-h3 success">{submitSuccess}</div>}
          {submitSuccess === 'There was an error submitting the form.' && <div className="inter-headings-h3 fail">{submitSuccess}</div>}
          {/* Add input fields */}
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter your name"
            />
            {errors.name && <span className="error-message">{errors.name}</span>}
          </div>

          {/* Other fields remain unchanged */}
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your email"
            />
            {errors.email && <span className="error-message">{errors.email}</span>}
          </div>

          <div className="form-group">
            <label htmlFor="contactNumber">Contact Number</label>
            <input
              type="text"
              id="contactNumber"
              name="contactNumber"
              value={formData.contactNumber}
              onChange={handleChange}
              placeholder="Enter your contact number"
            />
            {errors.contactNumber && <span className="error-message">{errors.contactNumber}</span>}
          </div>

          <div className="form-group">
            <label htmlFor="position">Position</label>
            <input
              type="text"
              id="position"
              name="position"
              value={formData.position}
              onChange={handleChange}
              placeholder="Enter the position you're applying for"
            />
            {errors.position && <span className="error-message">{errors.position}</span>}
          </div>

          <div className="form-group">
            <label htmlFor="linkedIn">LinkedIn Profile</label>
            <input
              type="text"
              id="linkedIn"
              name="linkedIn"
              value={formData.linkedIn}
              onChange={handleChange}
              placeholder="Enter your LinkedIn profile link"
            />
            {errors.linkedIn && <span className="error-message">{errors.linkedIn}</span>}
          </div>

          <div className="form-group">
            <label htmlFor="resume">Upload Resume (PDF only)</label>
            <input
              type="file"
              id="resume"
              name="resume"
              accept="application/pdf"
              ref={resumeRef} // Attach ref to the input
              onChange={handleFileChange}
            />
            {errors.resume && <span className="error-message">{errors.resume}</span>}
          </div>

          <div className="form-group">
            <label htmlFor="about">Tell us about yourself</label>
            <textarea
              id="about"
              name="about"
              value={formData.about}
              onChange={handleChange}
              placeholder="Share your background and experience"
            />
            {errors.about && <span className="error-message">{errors.about}</span>}
          </div>

          <button className="info-button" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default InformationForm;
