import React from "react";
import Navbar from "../components/Navbar";
import { lazy, Suspense } from 'react';
import {useState , useEffect} from "react";
import Footer from "../components/footer";

import ScrollToTop from "../components/ScrollToTop"

import mainBanner from "../assets/img/main_banners/ProductionManagementpage.webp";

import DetailImg from "../assets/img/production_management/EnuvisioncomprehensiveProductionManagementTraining.jpg";

import resourceBackImg from "../assets/img/linesbackground/ProductionManagement/2.png";


const ServiceMainPage = lazy(() => import('../components/ServiceMainPage'));
const ServiceDetailPage = lazy(() => import('../components/ServiceDetailPage'));
const ResourceTable = lazy(() => import('../components/ResourceTable'));




const mainContent = {
  heading: "Production Management Training",
  title: "",
  para: "",
  button: "Book a Consultant",
  image: mainBanner,
};

const data = {
  heading: "Enuvision comprehensive Production Management Training  ",
  para: "Our Production Management Training is a comprehensive program designed to equip professionals with the knowledge and skills required to effectively manage and optimize production processes. This training covers all aspects of production management, from planning and scheduling to quality control and continuous improvement. Participants will gain a deep understanding of the principles and practices that drive efficient production operations, ensuring they are well-prepared to tackle the challenges of modern manufacturing environments.",
  image1: DetailImg,
  points: [],
};

const resourceData = {
  heading: "Our Approach",
  para: "",
  back_img: resourceBackImg,
  columns: [
    {
      title: "Planning based on market production",
      // icon : ,
      points: [
        { point: "Generate Statistical Baseline Forecast", subpoint: [] },
        {
          point: "White BoxMake change in excel sheet as per the requirement",
          subpoint: [],
        },
        { point: "Import forecasting", subpoint: [] },
        { point: "To view demand forecast", subpoint: [] },
        { point: "Forecast/Master scheduling", subpoint: [] },
        { point: "Planned orders", subpoint: [] },
        { point: "View planned batch orders and firm", subpoint: [] },
        { point: "Transfer quantity between warehouses", subpoint: [] },
        {
          point: "Generated batch orders processing",
          subpoint: [
            "Run batch order for Premix (Semi finished good)",
            "Run batch order for other premixes",
            "Run batch order for Finished good",
          ],
        },
      ],
    },
    {
      title: "Replenishment based on actual demand (From shop)",
      // icon : ,
      points: [
        { point: "Special order production", subpoint: [] },
        {
          point:
            "Operationally produced BOM quantity is more than batch order quantity",
          subpoint: [],
        },
        {
          point:
            "Operationally produced BOM quantity is less than batch order quantity",
          subpoint: [],
        },
        {
          point: "Spare Raw material at floor after production completion",
          subpoint: [],
        },
        { point: "Co-product production", subpoint: [] },
      ],
    },
  ],
};

export const ProductionManagement = () => {
  const [loadedBanner , setLoadedBanner] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = mainContent.image;
    img.onload = () => {
      setLoadedBanner(true);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContent.image]);

  if(!loadedBanner){
    return;
  }

  return (
    <div className="ProductionManagement">
      <ScrollToTop />
      <Navbar />
      <Suspense>
        <ServiceMainPage mainContent={mainContent} />
      </Suspense>
      <Suspense>
        <ServiceDetailPage details={data} />
      </Suspense>
      <Suspense>
        <ResourceTable data={resourceData} />
      </Suspense>
      <Footer />
    </div>
  );
};

export default ProductionManagement;
