import React, { useState } from 'react';
import './InformationForm.css';

const InformationForm = () => {

  const [submitSuccess, setSubmitSuccess] = useState(null); // State for submission status

  const [formData, setFormData] = useState({
    name: '',
    address: '',
    email: '',
    contactNumber: '',
    Comments: ''
  });

  const [errors, setErrors] = useState({
    name: '',
    address: '',
    email: '',
    contactNumber: '',
    Comments: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = 'Name is required';
      valid = false;
    }
    if (!formData.address) {
      newErrors.address = 'Address is required';
      valid = false;
    }
    if (!formData.email) {
      newErrors.email = 'Email is required';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
      valid = false;
    }
    if (!formData.contactNumber) {
      newErrors.contactNumber = 'Contact Number is required';
      valid = false;
    } else if (!/^\d+$/.test(formData.contactNumber)) {
      newErrors.contactNumber = 'Contact Number must be digits only';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Handle form submission
      console.log('Form submitted:', formData);
      
      fetch("https://localhost:4000", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formData),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
          }
          return response.json(); // Parse the JSON from the response
        })
        .then(data => {
          console.log('Data received:', data); // Handle the data from the API
          setSubmitSuccess('Form submitted successfully!'); // Set success message
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
          setSubmitSuccess('There was an error submitting the form.'); // Set error message
        });

      // Reset the form fields
      setFormData({
        name: '',
        address: '',
        email: '',
        contactNumber: '',
        Comments: '',
      });
    }
  };

  return (
    <div className="information-form-section">
        <div className="information-form-container">
            <h2>Information Form</h2>
            <form onSubmit={handleSubmit} className="information-form">
                {/* Display success or error message */}
                {submitSuccess === 'Form submitted successfully!' && (
                  <div className="inter-headings-h3 success">{submitSuccess}</div>
                )}
                {submitSuccess === 'There was an error submitting the form.' && (
                  <div className="inter-headings-h3 fail">{submitSuccess}</div>
                )}
                <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter your name"
                />
                {errors.name && <span className="error-message">{errors.name}</span>}
                </div>

                <div className="form-group">
                <label htmlFor="address">Company Name</label>
                <input
                    type="text"
                    id="address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    placeholder="Enter your Company Name"
                />
                {errors.address && <span className="error-message">{errors.address}</span>}
                </div>

                <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter your email"
                />
                {errors.email && <span className="error-message">{errors.email}</span>}
                </div>

                <div className="form-group">
                <label htmlFor="contactNumber">Contact Number</label>
                <input
                    type="text"
                    id="contactNumber"
                    name="contactNumber"
                    value={formData.contactNumber}
                    onChange={handleChange}
                    placeholder="Enter your contact number"
                />
                {errors.contactNumber && <span className="error-message">{errors.contactNumber}</span>}
                </div>


                <div className="form-group">
                <label htmlFor="Comments">How Can We Help You?</label>
                <input
                    type="text"
                    id="comments"
                    name="Comments"
                    value={formData.Comments}
                    onChange={handleChange}
                    placeholder="Type here"
                />
                {errors.Comments && <span className="error-message">{errors.Comments}</span>}
                </div>

                <button className='info-button' type="submit">Submit</button>
            </form>
        </div>
    </div>
  );
};

export default InformationForm;
