import { lazy , Suspense } from "react";
import {useState , useEffect} from "react";

import Navbar from "../components/Navbar";
import Footer from "../components/footer";

import mainBanner from "../assets/img/main_banners/Trainingpage.webp";

import DetailImg from "../assets/img/training/AboutOurTrainings.jpg";

import gridBackImg from "../assets/img/training/TrainingModulesbackground.jpg";

import { faPlane } from "@fortawesome/free-solid-svg-icons";
import { faPieChart } from "@fortawesome/free-solid-svg-icons";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { faCloud } from "@fortawesome/free-solid-svg-icons";
import ScrollToTop from "../components/ScrollToTop";

const ServiceMainPage = lazy(() => import('../components/ServiceMainPage'));
const ServiceDetailPage = lazy(() => import('../components/ServiceDetailPage'));
const ServiceGridPoints = lazy(() => import('../components/ServiceGridPoints'));


const mainContent = {
  heading: "Professional Training Services ",
  title:
    "Elevating Skills, Driving Success: Expert Training Tailored for Your Growth.",
  para: "",
  button: "Let's Connect",
  image: mainBanner,
};

const data = {
  heading: "About Our Trainings",
  para: "Enuvision's training program prioritizes employee growth and development at all levels. We offer comprehensive onboarding, skills development, leadership training, technical updates, compliance education, and cross-functional learning. Our program is integrated with performance management, ensuring alignment with organizational goals and individual progress. Through continuous learning and development, we cultivate a skilled and empowered workforce poised for success.",
  image1: DetailImg,
  points: [],
};

const GridPoints = {
  heading_1: {
    text: "",
    light: false,
  },
  heading_2: {
    text: "Training Modules",
    light: false,
  },
  back_img: gridBackImg,
  points: [
    {
      title: "Supply Chain Management",
      icon: faPlane,
      link: "/supplychainManagementTraining",
      content:
        "We offer specialized Supply Chain training in Microsoft Dynamics 365, designed to elevate your consultancy skills and accelerate your career growth in the field.",
    },
    {
      title: "Finance Management",
      icon: faPieChart,
      link: "/financeManagementTraining",
      content:
        "We provide specialized Finance training in Microsoft Dynamics 365, aimed at boosting your consultancy skills and advancing your career in the financial industry.",
    },
    {
      title: "Production Management",
      icon: faLightbulb,
      link: "/productManagementTraining",
      content:
        "Our specialized training in Microsoft Dynamics 365 is tailored to elevate your consultancy expertise and propel your career in production management, equipping you with the skills needed to drive efficiency and success in the industry.",
    },
    {
      title: "Retail Management",
      icon: faCloud,
      link: "/retailManagementTraining",
      content:
        "Our specialized training in Microsoft Dynamics 365 enhances your consultancy skills and advances your career in retail management, empowering you to drive efficiency in the industry.",
    },
  ],
};

export const Training = () => {
  const [loadedBanner , setLoadedBanner] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = mainContent.image;
    img.onload = () => {
      setLoadedBanner(true);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContent.image]);

  if(!loadedBanner){
    return;
  }

  return (
    <div className="Training">
      <ScrollToTop />
      <Navbar />
      <Suspense>
        <ServiceMainPage mainContent={mainContent} />
      </Suspense>
      <Suspense>
        <ServiceDetailPage details={data} />
      </Suspense>
      <Suspense>
        <ServiceGridPoints data={GridPoints} />
      </Suspense>
      <Footer />
    </div>
  );
};

export default Training;
